.container {
    width: 90%;
    margin: 100px auto 0;
}

.title h2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 20px;
}

.table {
    width: 100%;
}

.trHead {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
}

.trHead th {
    flex: 0 1 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    text-align: center;
    writing-mode: normal;
}

.trBody td,
.trHead th {
    border-right: 1px solid black;
}

.trBody td:last-child,
.trHead th:last-child {
    border-right: none;
}

.trBody {
    border: 1px solid black;
    border-top: none;
    display: flex;
    justify-content: space-between;

}

.trBody td {
    flex: 0 1 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #1D1D1F;
    text-align: center;

}

.trBody td:nth-child(1),
.trBody td:nth-child(7) {
    color: black;
    font-weight: 600;

}

@media(max-width:1850px) {

    .trBody td,
    .trHead th {
        font-size: 19px;
    }
}

@media(max-width:1420px) {

    .trBody td,
    .trHead th {
        font-size: 17px;
    }
}

@media(max-width:992px) {

    .trBody td,
    .trHead th {
        font-size: 15px;
    }
}

@media(max-width:768px) {
    .table {
        display: flex;
        justify-content: center;
        height: 800px;
    }

    .trHead {
        flex-direction: column;
    }

    .trBody {
        flex-direction: column;
        border-top: 1px solid black;
    }

    .tbody,
    .thead {
        display: flex;
        flex: 0 1 33%;

    }

    .trHead,
    .trBody {
        width: 100%;
    }

    .trHead th,
    .trBody td {
        font-size: 15px;
    }

    .trBody td,
    .trHead th {
        height: 50px;
        border-right: none;
        border-bottom: 1px solid black;

    }



}