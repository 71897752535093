.bg {
    margin-top: 100px;
    background: #140C25;
    padding: 80px 0 100px;
}

.container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.textTop p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}

.title h2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #D34A44;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
}

.line {
    width: 300px;
    height: 5px;
    background: #FFFFFF;
    margin-bottom: 10px;
}

.textBottom ul li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    white-space: break-spaces;

}

.textBottom ul {
    list-style: inside;

}

.textBottom ul li::marker {
    color: #D34A44;
    font-size: 25px;
}

@media(max-width:768px) {
    .bg {
        margin-top: -5px;
    }

    .container {
        width: 90%;
    }

    .textTop p {
        font-size: 20px;
    }

    .title h2 {
        font-size: 25px;
    }

    .textBottom p {
        font-size: 18px;
    }

    .textBottom ul li {
        margin-bottom: 15px;
    }
}