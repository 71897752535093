body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background-color: #010d20;
    cursor: pointer;

}

body::-webkit-scrollbar-thumb {
    background-color: #e44840;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;
    width: 5px;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #0444a4;
}