.container {
    width: 70%;
    margin: 5% auto;
}

.about_company h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 32px;
}

.about_company {
    display: flex;
    justify-content: space-between;
}



.about_co_text {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
    flex: 0 1 55%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;


}

.about_co_img {
    width: 30%;
}

#line {
    width: 30%;
    background-color: #140C25;
    height: 3px;
}

.about_co_img img {
    width: 100%;
    aspect-ratio: 31/45;
    object-position: top 0 left 60%;
    object-fit: cover;
}

@media(max-width:992px) {
    .about_company h2 {
        font-size: 28px;
    }

    .about_company {
        flex-direction: column;
        justify-content: space-between;
    }

    .about_co_text {
        width: 90%;
        align-items: center;
        gap: 20px;
        margin: 0 auto;
        text-align: center;

    }

    .about_co_img {
        width: 100%;
        margin-top: 60px;
    }

    .about_co_img img {
        width: 100%;
        height: 350px;
    }

    .container h2 {
        text-align: center;
    }

    .about_co_text {
        flex: 0 1 100%;
    }
}

@media (max-width:768px) {
    .container {
        width: 100%;
        margin: 5% auto 0;
    }

    .about_company {
        flex-direction: column;
        justify-content: space-between;
    }

    .about_co_text {
        width: 90%;
        align-items: center;
        font-size: 15px;
        line-height: 180%;

        gap: 20px;
        margin: 0 auto;
    }

    .about_co_img {
        width: 100%;
        margin-top: 60px;
    }

    .about_co_img img {
        width: 100%;
        height: 350px;
    }

    .container h2 {
        text-align: center;
    }

    .about_co_text {
        flex: 0 1 100%;
    }
}