.container {
    width: 80%;
    margin: 5% auto 0;
}

.container h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 33px;
    text-align: center;
}

.quality_text {
    display: flex;
    justify-content: center;
}

.quality_text p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 180%;
    width: 70%;
    margin-top: 10px;
}

.quality_img {
    width: 100%;
}

.quality_img img {
    width: 100%;
}

.line {
    background-color: #140C25;
    height: 10vh;
}

@media(max-width:992px) {
    .container h2 {
        font-size: 28px;
    }
}

@media (max-width:768px) {
    .container {
        width: 100%;
        margin: 5% auto 0;
    }

    .container h2 {
        width: 90%;
        margin: 0 auto;
    }

    .quality_text p {
        width: 90%;
    }

    .quality_text p {
        line-height: 120%;

        font-size: 18px;
    }

    .quality_img {
        width: 100%;
        height: 300px;
    }

    .quality_img img {
        width: 100%;
        height: 300px;
        object-fit: cover;

    }

}