header {
    position: relative;
    height: 100vh;
}

.bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container {
    width: 70%;
    height: 100%;
    margin: 0 auto;

}

.navigation {
    height: 120px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.menu ul {
    display: flex;
    gap: 30px;
    list-style: none;
}


.boxLogo {
    width: 300px;
}

.boxLogo img {
    width: 100%;
}

.menu_item {
    color: #fff;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    transition: 0.3s;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
    will-change: scroll-position;
}

.menu_item:hover {
    color: #D34A44;
}


.textLeft {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}




.nameCompany h1 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    padding-left: 40px;
    color: #fff;


}




.title {
    width: 50%;
}

.title article p {
    padding-left: 40px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px;
}


@media(max-width:992px) {
    .menu_item {
        font-size: 18px;
    }

    .title {
        width: 80%;
    }
}

@media(max-width:768px) {
    .container {
        width: 90%;
    }

    .boxLogo {
        margin: 0 auto;
    }

    .menu {
        display: none;
    }

    .textLeft {
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 15px;
    }

    .title {
        width: 100%;
    }

    .nameCompany h1 {
        text-align: center;
        padding-left: 0;
    }

    .title article p {
        font-size: 15px;
        text-align: center;
        padding-left: 0;

    }



    .boxLogo {
        width: 150px;
    }

    .boxLogo img {
        width: 100%;
    }

}