.bg {
    margin-top: 100px;
    padding: 60px 0 10px;
    background: #140C25;
}

.container {
    width: 80%;
    margin: 0 auto 50px;
}

.boxContact {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    color: #FFFFFF;

}

.boxTelephone {
    flex: 0 1 20%;
}

.boxAddress {
    flex: 0 1 40%;

}

.boxEmail {
    flex: 0 1 20%;

}

.boxLicense {
    flex: 0 1 30%;

}

.boxContact h2 {
    margin-bottom: 10px;
}

.boxContact p {
    color: #ffffffb3;
    transition: 0.3s;
}

.boxContact p:hover {
    color: #fff;
}

.boxContact a {
    transition: 0.3s;

}

.boxContact a:hover {
    text-decoration: underline 2px white;
}

.copyright {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
}

@media(max-width:1100px) {
    .boxContact {
        font-size: 18px;
    }
}

@media(max-width:992px) {
    .boxContact {
        font-size: 15px;
    }
}

@media(max-width:768px) {
    .boxContact {
        font-size: 13px;
    }

    .boxContact {
        flex-direction: column;
        gap: 20px;
    }

    .boxContact p {
        font-size: 20px;
    }

    .copyright {
        font-size: 13px;
    }
}