.burgerMenu {
    width: 50px;
    height: 50px;
}

.menu_btn_active,
.menu_btn {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    background: #D34A44;
    border-radius: 10px;

}


.menu_btn_active span,
.menu_btn span {
    width: 30px;
    height: 2px;
    position: absolute;
    background-color: #fff;
    transition: all 0.2s;
    z-index: 100;
    left: 20%;
    top: 25%;
}


.menu_btn span:nth-of-type(2) {
    top: calc(55% - 5px);
}

.menu_btn span:nth-of-type(3) {
    top: calc(55% + 5px);
}

.menu_btn_active span:nth-of-type(1) {
    display: none;
}

.menu_btn_active span:nth-of-type(2) {
    top: 45%;
    transform: rotate(45deg);
}

.menu_btn_active span:nth-of-type(3) {
    top: 45%;
    transform: rotate(-45deg);
}


.menu_active,
.menu {
    position: fixed;
    width: 80%;
    top: 0px;
    right: 0px;
    height: 100%;
    background: #44479c;
    transition: 0.5s;
    z-index: 80;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 5%;
}

.menu_active {
    transform: translateX(0);

}

.navi {
    display: flex;
    flex-direction: column;
    height: 70%;
    margin-right: 30px;
}

.navi ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: end;

}

.navi ul li a {
    font-size: 25px;
}

.menu {

    transform: translateX(100%);

}

.menu_item {
    color: #fff;
}

.button_heder_conect {
    height: 50px;
    border-radius: 10px;
    background: #D34A44;
    border: none;
}

.button_heder_conect a {
    font-size: 18px;

    color: #fff;
}

.menu nav ul li {
    list-style-type: none;
}

.connect_icons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.connect_icons a {
    cursor: pointer;
    flex: 0 1 40%;
}

.connect_icons a img {
    width: 100%;
}

@media (min-width:768px) {
    .burgerMenu {
        display: none;
    }
}